'use client'

import React, { HTMLAttributes, RefObject, useRef, useState, useEffect, Fragment } from 'react'
import { useScroll, useMotionValueEvent, MotionValue } from 'framer-motion'
import { cn, pickRequiredParams } from '@/lib/utils'
import ViverseButton from '@/components/ViverseButton'
import { useTranslations } from 'next-intl'
import AdvenceFeaturesSvgAnim from '@/app/AdvenceFeaturesSvgAnim'

export type AdvancedFeature = {
  title: { text: string; className: string }
  subtitle: { text: string; className: string }
  desc: { text: string; className: string }
  button: { text: string; href: string; className: string }
  imageHref: { insideSvg: string; ctaLink: string; ctaText: string }
}

interface AdvancedFeaturesProps extends HTMLAttributes<HTMLDivElement> {}

function AdvancedFeatures({ className, ...props }: AdvancedFeaturesProps) {
  const t = useTranslations()

  const advancedFeatures = [
    {
      subtitle: { text: t('landing-page.advanced-1-subtitle'), className: '' },
      title: { text: t('landing-page.advanced-1-title'), className: '' },
      desc: {
        text: t('landing-page.advanced-1-desc'),
        className: '',
      },
      button: {
        text: t('landing-page.advanced-1-button'),
        href: '/profile',
        className: '',
      },
      imageHref: {
        insideSvg: '',
        ctaLink: '',
        ctaText: '',
      },
    },
    {
      subtitle: { text: t('landing-page.advanced-2-subtitle'), className: '' },
      title: { text: t('landing-page.advanced-2-title'), className: '' },
      desc: {
        text: t('landing-page.advanced-2-desc'),
        className: '',
      },
      button: {
        text: t('landing-page.advanced-2-button'),
        href: '/creator-intro',
        className: '',
      },
      imageHref: {
        insideSvg: '/images/advence-features-2.png',
        ctaLink: '',
        ctaText: '',
      },
    },
    {
      subtitle: { text: t('landing-page.advanced-3-subtitle'), className: '' },
      title: { text: t('landing-page.advanced-3-title'), className: '' },
      desc: {
        text: t('landing-page.advanced-3-desc'),
        className: '',
      },
      button: {
        text: t('landing-page.advanced-3-button'),
        href: '/creator-intro',
        className: '',
      },
      imageHref: {
        insideSvg: '/images/advence-features-3.png',
        ctaLink: '',
        ctaText: '',
      },
    },
    {
      subtitle: { text: t('landing-page.advanced-4-subtitle'), className: '' },
      title: { text: t('landing-page.advanced-4-title'), className: '' },
      desc: {
        text: t('landing-page.advanced-4-desc'),
        className: '',
      },
      button: {
        text: t('landing-page.advanced-4-button'),
        href: 'https://www.viverse.com/polygon-streaming',
        className: '',
      },
      imageHref: {
        insideSvg: '/images/advence-features-4.png',
        ctaLink: '',
        ctaText: '',
      },
    },
    {
      subtitle: { text: t('landing-page.advanced-5-subtitle'), className: '' },
      title: { text: t('landing-page.advanced-5-title'), className: '' },
      desc: {
        text: t('landing-page.advanced-5-desc'),
        className: '',
      },
      button: {
        text: t('landing-page.advanced-5-button'),
        href: 'https://avatar.viverse.com/avatar',
        className: '',
      },
      imageHref: {
        insideSvg: '',
        ctaLink: '',
        ctaText: '',
      },
    },
  ]

  const scrollRef = useRef<HTMLElement>(null)
  const svgRef = useRef<SVGSVGElement>(null)

  const [sectionInView, setSectionInView] = useState(true)

  const { scrollXProgress: containerXprogress, scrollYProgress: containerYprogress } = useScroll({
    container: scrollRef,
  })

  function handleScroll() {
    if (!scrollRef.current) {
      console.warn('scrollRef is not existed !')
      return
    }
    let scrollingInfo = scrollRef.current.getBoundingClientRect()

    if (!scrollingInfo) return

    const { height, y } = scrollingInfo

    let finalY = (window.innerHeight - height) / 2 // this is the final y value we expect the element to fix in

    if (Math.abs(y) < height) {
      setSectionInView(true)

      if (window.innerWidth >= 1024) {
        const dy = y - finalY

        if (dy <= 1) {
          return
        }

        if (Math.abs(dy) > 1) {
          window.scrollBy({
            top: dy,
            left: 0,
            behavior: 'smooth',
          })
        }
      }
    } else {
      setSectionInView(false)
    }
  }

  useEffect(() => {
    document.addEventListener('scrollend', handleScroll)

    return () => {
      document.removeEventListener('scrollend', handleScroll)
    }
  }, [])

  return (
    <section
      className={cn(
        'no-scrollbar relative flex h-[860px] w-full snap-x snap-mandatory flex-wrap justify-start overflow-x-auto overflow-y-hidden',
        `lg:mx-auto lg:h-[430px] lg:snap-y lg:justify-center 2xl:h-[540px] ${sectionInView ? 'lg:overflow-y-auto' : 'lg:overflow-y-hidden'} lg:w-fit lg:overflow-x-hidden`,
        className,
      )}
      ref={scrollRef}
      {...props}
    >
      <div
        className={cn(
          '2xl;gap-[70px] container relative flex h-full w-max snap-x snap-mandatory flex-col-reverse items-start justify-center lg:snap-y lg:gap-[36px]',
          'lg:h-fit lg:w-full lg:flex-row lg:justify-between',
        )}
      >
        <div
          className={cn(
            'relative flex h-fit w-max snap-x snap-mandatory pl-0',
            'lg:block lg:w-fit lg:max-w-[430px] lg:snap-y',
            '2xl:w-fit 2xl:max-w-[640px] 2xl:pl-[60px]',
          )}
        >
          {advancedFeatures.map((advancedFeature, i) => (
            <AdvancedFeatureIntro key={`AdvancedFeatureIntro${i}`} {...advancedFeature} />
          ))}
        </div>
        <div></div>
        <div className="sticky left-[calc(50%-195px)] flex h-[456px] items-center overflow-hidden md:left-[calc(50%-270px)] md:size-[606px] lg:left-auto lg:top-0 lg:size-[428px] 2xl:size-[540px]">
          {
            <ArtImageArea
              scrollXProgress={containerXprogress}
              scrollYProgress={containerYprogress}
              svgRef={svgRef}
              imgHref={pickRequiredParams(advancedFeatures, 'imageHref')}
              sectionInView={sectionInView}
            />
          }
        </div>
      </div>
    </section>
  )
}

function AdvancedFeatureIntro({ title, subtitle, desc, button }: AdvancedFeature) {
  return (
    <section className="relative mx-6 flex w-[calc(100vw-48px)] snap-center snap-always items-center p-0 md:mx-[5vw] md:w-[90vw] md:p-[30px] lg:m-0 lg:h-[430px] lg:w-fit lg:max-w-screen-sm 2xl:h-[540px] 2xl:w-[640px]">
      <div className="relative grid h-fit w-full gap-[10px]">
        <div
          className={cn(
            'text-[20px] font-normal uppercase leading-[32px] tracking-[0.15px] text-vive-white',
            'lg:text-[32px] lg:leading-10',
            '2xl:text-[40px] 2xl:leading-none',
            subtitle.className,
          )}
        >
          {subtitle.text}
        </div>
        <div
          className={cn(
            'mb-[26px] text-[54px] font-bold uppercase leading-[76.8px] tracking-[0.12px] text-vive-white',
            'lg:text-[72px] lg:leading-tight lg:tracking-[-2px]',
            '2xl:text-[96px] 2xl:leading-none',
            title.className,
          )}
        >
          {title.text}
        </div>
        <div className={cn('body-1 mb-[14px] tracking-[0.22px]', desc.className)}>{desc.text}</div>
        <a target="_blank" href={button.href} className="size-fit">
          <ViverseButton
            size="large"
            className={cn(
              'body-1 tracking-[0.22px] lg:min-w-[200px] lg:py-[10px]',
              button.className,
            )}
          >
            {button.text}
          </ViverseButton>
        </a>
      </div>
    </section>
  )
}

const PAGE_POINT_MARGIN = 8

function ArtImageArea({
  scrollXProgress,
  scrollYProgress,
  svgRef,
  imgHref,
  sectionInView,
}: {
  scrollXProgress: MotionValue<number>
  scrollYProgress: MotionValue<number>
  svgRef: RefObject<SVGSVGElement>
  imgHref: { insideSvg: string; outsideSvg: string; ctaLink: string; ctaText: string }[]
  sectionInView: boolean
}) {
  const [imgOpacity, setImgOpacity] = useState<number[]>(
    Array.from({ length: imgHref.length }, (_, i) => {
      return i === 0 ? 1 : 0
    }),
  )
  const [pageTransitionPoint, setPageTransitionPoint] = useState(
    Array.from({ length: imgHref.length }, () => {
      return { width: 0, x: 0, fill: '#364356' }
    }),
  )

  function handleScroll(lastestValue: number, axis: 'x' | 'y') {
    if ((window.innerWidth < 1024 && axis === 'x') || (window.innerWidth >= 1024 && axis === 'y')) {
      const tempTransitionPoint = Array.from({ length: imgHref.length }, () => {
        return { width: 0, x: 0, fill: '#364356' }
      })

      let currPage = Number.parseFloat((lastestValue * (imgHref.length - 1)).toFixed(1))

      for (let i = 0; imgHref[i]; i++) {
        if (Math.abs(i - currPage) < 0.5) {
          tempTransitionPoint[i].fill = '#00B3E3'
        } else {
          tempTransitionPoint[i].fill = '#364356'
        }
        if (i == 0) {
          tempTransitionPoint[i].x = 0
        } else {
          tempTransitionPoint[i].x =
            tempTransitionPoint[i - 1].width + tempTransitionPoint[i - 1].x + PAGE_POINT_MARGIN
        }
        if (Math.abs(i - currPage) > 1) {
          tempTransitionPoint[i].width = 6
        } else {
          tempTransitionPoint[i].width = 6 + 18 - Math.abs(i - currPage) * 18
        }
      }

      setPageTransitionPoint(tempTransitionPoint)

      let rotation = lastestValue * (imgHref.length - 1) * 120
      if (svgRef && svgRef.current) {
        svgRef.current.style.transform = `rotate(${rotation}deg)`
      }
      let fadeIn = (rotation % 120) / 120
      let fadeOut = 1 - fadeIn

      const tempOpacityArr = Array.from({ length: imgHref.length }, () => 0)

      for (let i = 0; imgHref[i]; i++) {
        if (rotation >= (i + 1) * 120) {
          tempOpacityArr[i] = 0
        } else if (rotation >= (i + 0.5) * 120) {
          tempOpacityArr[i] = Number.parseFloat(fadeOut.toFixed(2))
        } else if (rotation >= i * 120) {
          tempOpacityArr[i] = 1
        } else if (rotation >= (i - 0.5) * 120) {
          tempOpacityArr[i] = Number.parseFloat(fadeIn.toFixed(2))
        } else {
          tempOpacityArr[i] = 0
        }
        if (tempOpacityArr[i] > 1) tempOpacityArr[i] = 1
        if (tempOpacityArr[i] < 0) tempOpacityArr[i] = 0
      }

      setImgOpacity(tempOpacityArr)
    }
  }

  useMotionValueEvent(scrollXProgress, 'change', function (lastestValue: number) {
    handleScroll(lastestValue, 'x')
  })
  useMotionValueEvent(scrollYProgress, 'change', function (lastestValue: number) {
    handleScroll(lastestValue, 'y')
  })

  return (
    <div className="grid h-full w-fit items-center justify-items-center duration-200">
      <div
        className={cn(
          'group relative size-[390px] transition-opacity duration-500 md:size-[540px] lg:size-[422px] 2xl:size-[533px]',
          { 'opacity-100': sectionInView },
          { 'opacity-0': !sectionInView },
        )}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="1024"
          height="1024"
          viewBox="0 0 1024 1024"
          fill="none"
          id="ArtImageArea"
          className="absolute size-[390px] md:size-[540px] lg:size-[422px] 2xl:size-[540px]"
          ref={svgRef}
        >
          <path
            d="M975.246 741.106C984.952 717.256 990.324 691.736 990.184 666.485C989.776 607.788 964.808 549.582 940.753 496.928C885.988 377.193 801.329 271.844 702.128 185.958C676.296 163.558 649.102 142.369 619.281 125.511C565.089 94.9453 500.219 80.1249 439.617 99.2829C385.821 116.328 342.396 154.653 307.362 197.733C284.1 226.336 264.08 257.364 245.182 288.913C179.52 398.374 131.465 525.038 118.246 652.673C107.992 751.207 134.682 864.452 231.066 910.981C345.418 966.145 486.433 957.695 608.56 939.003C675.231 928.792 740.916 911.33 803.359 885.472C847.543 867.214 892.532 846.525 927.281 812.751C947.64 792.88 964.276 768.077 975.246 741.106Z"
            stroke="url(#paint0_linear_1066_27021)"
            strokeWidth="4"
          />
          <path
            d="M921.976 741.891C932.152 718.2 938.016 692.757 938.352 667.481C939.047 608.725 915.12 550.004 892.002 496.857C839.369 376 756.505 268.987 658.699 181.184C633.231 158.284 606.376 136.572 576.805 119.146C523.068 87.5471 458.333 71.5097 397.235 89.5598C342.999 105.622 298.755 143.175 262.832 185.642C238.98 213.839 218.332 244.523 198.798 275.748C130.929 384.088 80.3823 509.972 64.7315 637.474C52.6003 735.905 77.2181 849.745 172.94 898.104C286.509 955.439 427.996 949.599 550.748 933.157C617.76 924.174 683.919 907.916 746.988 883.194C791.614 865.74 837.093 845.868 872.555 812.709C893.333 793.198 910.473 768.682 921.976 741.891Z"
            stroke="url(#paint1_linear_1066_27021)"
            strokeWidth="4"
          />
          {imgHref.map((svgData, i) => {
            return (
              <g
                key={`art-image-area-img-${i}`}
                style={{
                  opacity: imgOpacity[i],
                  pointerEvents: imgOpacity[i] === 1 ? 'all' : 'none',
                }}
              >
                <path
                  id={`img${i}Path`}
                  d="M900.311 726.741C909.121 705.901 914.141 683.544 914.314 661.359C914.642 609.789 893.378 558.364 872.852 511.828C826.118 406.003 752.94 312.47 666.753 235.867C644.31 215.887 620.656 196.957 594.642 181.801C547.368 154.319 490.524 140.546 437.035 156.672C389.552 171.022 350.934 204.186 319.637 241.625C298.857 266.483 280.898 293.509 263.917 321.005C204.924 416.405 161.203 527.123 148.091 639.097C137.925 725.541 160.059 825.338 244.23 867.333C344.094 917.124 468.136 911.339 575.698 896.336C634.418 888.14 692.355 873.562 747.542 851.571C786.591 836.045 826.377 818.392 857.314 789.125C875.441 771.904 890.353 750.307 900.311 726.741Z"
                  fill={`url(#img${i}InPattern)`}
                />
                {svgData.ctaLink && svgData.ctaText && (
                  <path
                    id={`art-image-area-img-path-over-${i}`}
                    className="fill-transparent group-hover:fill-[#000000B2]"
                    d="M900.311 726.741C909.121 705.901 914.141 683.544 914.314 661.359C914.642 609.789 893.378 558.364 872.852 511.828C826.118 406.003 752.94 312.47 666.753 235.867C644.31 215.887 620.656 196.957 594.642 181.801C547.368 154.319 490.524 140.546 437.035 156.672C389.552 171.022 350.934 204.186 319.637 241.625C298.857 266.483 280.898 293.509 263.917 321.005C204.924 416.405 161.203 527.123 148.091 639.097C137.925 725.541 160.059 825.338 244.23 867.333C344.094 917.124 468.136 911.339 575.698 896.336C634.418 888.14 692.355 873.562 747.542 851.571C786.591 836.045 826.377 818.392 857.314 789.125C875.441 771.904 890.353 750.307 900.311 726.741Z"
                    fill="#000000B2"
                  ></path>
                )}
              </g>
            )
          })}
          <defs>
            <linearGradient
              id="paint0_linear_1066_27021"
              x1="505.885"
              y1="87.7346"
              x2="582.303"
              y2="943.412"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#363864" />
              <stop offset="1" stopColor="#A8D2DD" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1066_27021"
              x1="34.4229"
              y1="431.436"
              x2="432.209"
              y2="888.905"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#323960" />
              <stop offset="1" stopColor="#98BCC6" />
            </linearGradient>
            <linearGradient
              id="paint0_linear_2589_21471"
              x1="-229.116"
              y1="408.901"
              x2="512.582"
              y2="1023.471"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#018FD2" />
              <stop offset="0.485" stopColor="#035FB3" />
              <stop offset="1" stopColor="#6D52BB" />
            </linearGradient>
            {imgHref.map((_, i) => {
              return (
                <Fragment key={`art-image-area-svg-patterns-${i}`}>
                  <pattern
                    id={`img${i}InPattern`}
                    patternUnits="userSpaceOnUse"
                    x="0"
                    y="0"
                    width="1024"
                    height="1024"
                  >
                    <rect
                      // @ts-ignore
                      transformOrigin="512 512"
                      transform={`rotate(${360 - i * 120})`}
                      width="1024"
                      height="1024"
                      fill="url(#paint0_linear_2589_21471)"
                    ></rect>
                    <image
                      // @ts-ignore
                      transformOrigin="512 512"
                      transform={`rotate(${360 - i * 120})`}
                      href={imgHref[i].insideSvg}
                      height="1024"
                      width="1024"
                    ></image>
                  </pattern>
                </Fragment>
              )
            })}
          </defs>
        </svg>

        <div
          className={cn(
            'group relative size-[390px] transition-opacity duration-500 md:size-[540px] lg:size-[422px] 2xl:size-[533px]',
            { 'opacity-100': sectionInView },
            { 'opacity-0': !sectionInView },
          )}
        >
          {imgHref.map((_, i) => {
            return (
              <>
                {/* import SVG animation can't work normally, so can only be embedded directly */}
                {imgOpacity[i] >= 0.75 && (
                  <AdvenceFeaturesSvgAnim opacity={imgOpacity[i]} id={i}></AdvenceFeaturesSvgAnim>
                )}
              </>
            )
          })}
        </div>

        {imgHref.map((svgData, i) => {
          return (
            svgData.ctaLink &&
            svgData.ctaText && (
              <a
                key={`art-image-area-cta-${i}`}
                target="_blank"
                href={svgData.ctaLink}
                className={cn(
                  'absolute left-1/2 top-1/2 size-fit translate-x-[-50%] translate-y-[-50%] transform',
                  `${imgOpacity[i] >= 0.5 ? 'group-hover:opacity-100' : 'opacity-0'} opacity-0`,
                )}
                style={{
                  pointerEvents: imgOpacity[i] === 1 ? 'all' : 'none',
                }}
              >
                <ViverseButton
                  variant="secondary"
                  size="large"
                  className={cn('body-1 tracking-[0.22px] lg:min-w-[200px] lg:py-[10px]')}
                >
                  {svgData.ctaText}
                </ViverseButton>
              </a>
            )
          )
        })}
      </div>
      <svg
        width="80"
        height="6"
        viewBox="0 0 80 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="lg:hidden"
      >
        {imgHref.map((_, i) => {
          return (
            <rect
              key={`pageTransitionPoint${i}`}
              width={pageTransitionPoint[i].width}
              x={pageTransitionPoint[i].x}
              height="6"
              rx="3"
              fill={pageTransitionPoint[i].fill}
            />
          )
        })}
      </svg>
    </div>
  )
}

export default AdvancedFeatures
