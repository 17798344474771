'use client'

import { HTMLAttributes, ReactNode } from 'react'
import { Skeleton } from '@/components/ui/skeleton'
import ActionWorldCard from '@/components/ActionWorldCard'
import { Room } from '@/services/cms'
import CarouselSlider from '@/components/CarouselSlider'
import ArrowRight from '@/components/assets/icon_arrow_right.svg'
import { cn } from '@/lib/utils'

interface RoomsCarouselProps extends HTMLAttributes<HTMLDivElement> {
  rooms: Room[]
  title?: string
  variant?: 'normal' | 'small-title'
  className?: string
  controllerClassName?: string
  carouselSliderClassName?: string
  onTitleClick?: HTMLAttributes<HTMLDivElement>['onClick']
  extraCustomCards?: ReactNode
  hideLiked?: boolean
}
function RoomsCarousel({
  title,
  variant = 'normal',
  onTitleClick,
  rooms,
  extraCustomCards,
  className,
  controllerClassName,
  carouselSliderClassName,
  hideLiked = false,
  ...props
}: RoomsCarouselProps) {
  function renderCards() {
    return rooms.map((room, index) => (
      <ActionWorldCard key={`world-card-${room.id}-${index}`} info={room} hideLiked={hideLiked} />
    ))
  }

  function renderTitle() {
    if (!title) return null

    return (
      <div
        className={`mx-6 mb-3 flex cursor-${onTitleClick ? 'pointer' : 'default'} items-center gap-2 md:mx-12 lg:mx-auto lg:mb-6 lg:max-w-[884px] 2xl:mx-0 2xl:ml-5`}
        onClick={onTitleClick}
      >
        <h2
          className={cn(
            variant === 'small-title' ? 'subheader-2' : 'subheader-1',
            'lg:header-4 !text-vive-primary-20',
          )}
        >
          {title}
        </h2>

        {onTitleClick && (
          <ArrowRight className="size-4 text-vive-primary-20 hover:text-vive-white lg:size-5" />
        )}
      </div>
    )
  }

  return (
    <div className={cn('mb-[30px] 2xl:mx-auto 2xl:mb-12', className)} {...props}>
      {renderTitle()}

      <CarouselSlider
        count={rooms.length}
        countOfRow={4}
        controllerClassName={cn('lg:mt-10 2xl:mt-[70px] lg:items-start', controllerClassName)}
        className={carouselSliderClassName}
      >
        {extraCustomCards}
        {renderCards()}
      </CarouselSlider>
    </div>
  )
}

export function RoomsCarouselSkeleton() {
  return (
    <div className="mb-[30px] md:pl-3 lg:mb-12 lg:pl-6">
      <Skeleton className="ml-6 w-[200px] md:ml-7 2xl:ml-0">
        <h2 className="subheader-1 mx-6 mb-3 md:mx-12 2xl:mx-0 2xl:mb-6 2xl:ml-5">{'123'}</h2>
      </Skeleton>
      <div className="no-scrollbar group relative flex overflow-x-auto px-6 md:pl-7 md:pr-12 2xl:w-[1340px] 2xl:overflow-hidden 2xl:px-0">
        <div className="flex">
          {Array.from({ length: 4 }, (i) => (
            <Skeleton
              key={`rooms-carousel-skeleton-card-${i}`}
              className="ml-3 h-[204px] w-[248px] first:ml-0 2xl:ml-5 2xl:h-[246px] 2xl:w-[310px]"
            />
          ))}
        </div>
      </div>
    </div>
  )
}

RoomsCarousel.Skeleton = RoomsCarouselSkeleton

export default RoomsCarousel
