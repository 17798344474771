import { useSlickSlide } from '@/hooks/useSlickSlide'
import ArrowButton from '@/components/ArrowButton'
import { cn } from '@/lib/utils'

const TABLET_SLIDER_FIXED_WIDTH = 884
const PC_SLIDER_FIXED_WIDTH = 1300
const CARD_SPACING = 20

type CarouselSliderProps = {
  children: React.ReactNode
  count: number
  countOfRow: number
  sliderFixedWidth?: number
  cardSpacing?: number
  className?: string
  controllerClassName?: string
  scrollBpt?: number
}
function CarouselSlider({
  children,
  count = 1,
  countOfRow = 1,
  sliderFixedWidth = PC_SLIDER_FIXED_WIDTH,
  cardSpacing = CARD_SPACING,
  className,
  controllerClassName,
  ...props
}: CarouselSliderProps) {
  const { slideIndex, maxIndex, nextSlide, prevSlide } = useSlickSlide(count, countOfRow)

  function SlickSlideController() {
    return (
      <div
        className={cn(
          'hidden duration-300 lg:pointer-events-none lg:absolute lg:flex lg:h-full lg:w-full lg:items-center lg:justify-between lg:opacity-0 lg:transition-opacity group-hover:lg:opacity-100',
          controllerClassName,
        )}
      >
        <ArrowButton
          direction="left"
          className={` ${slideIndex ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'} transition-opacity duration-300`}
          onClick={prevSlide}
        />
        <ArrowButton
          direction="right"
          className={` ${slideIndex + 1 > maxIndex ? 'pointer-events-none opacity-0' : 'pointer-events-auto opacity-100'} transition-opacity duration-300`}
          onClick={nextSlide}
        />
      </div>
    )
  }

  return (
    <div
      className={cn(
        `no-scrollbar group relative flex justify-start overflow-x-auto pl-0 pr-11 md:pl-7 md:pr-12 lg:m-auto lg:w-[918px] lg:overflow-hidden lg:px-0 2xl:w-[1340px]`,
        className,
      )}
      {...props}
    >
      <div
        className="flex gap-3 transition-transform duration-300 ease-in-out lg:gap-5"
        style={{
          transform: `translateX(${getTranslateX(slideIndex, cardSpacing)}px)`,
        }}
      >
        {children}
      </div>
      {count / countOfRow > 1 && <SlickSlideController />}
    </div>
  )
}

export default CarouselSlider

function getTranslateX(slideIndex: number, cardSpacing: number) {
  const windowWidth = window.innerWidth

  let sliderWidth = PC_SLIDER_FIXED_WIDTH

  if (windowWidth >= 1440) {
    sliderWidth = PC_SLIDER_FIXED_WIDTH
  } else if (windowWidth >= 1024) {
    sliderWidth = TABLET_SLIDER_FIXED_WIDTH
  }
  return -(sliderWidth + cardSpacing) * slideIndex + cardSpacing
}
