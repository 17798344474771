'use client'

import { HTMLAttributes, Suspense } from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/navigation'
import Button from '@/components/ViverseButton'
import RoomsCarousel from '@/components//RoomsCarousel'
import { EditorChoice } from '@/services/cms'
import { cn } from '@/lib/utils'

interface CuratedWorldsProps extends HTMLAttributes<HTMLDivElement> {
  editorChoices: EditorChoice[]
}

function CuratedWorlds({ editorChoices, className, ...props }: CuratedWorldsProps) {
  const t = useTranslations()
  const router = useRouter()

  const handleGoToExploreWorlds = () => {
    router.push('/worlds')
  }

  return (
    <section className={cn('flex flex-col 2xl:items-center', className)} {...props}>
      {editorChoices?.map(({ id, title, rooms }) => (
        <Suspense fallback={<RoomsCarousel.Skeleton />} key={`curated-worlds-editor-choice-${id}`}>
          <RoomsCarousel title={title} rooms={rooms} hideLiked />
        </Suspense>
      ))}
      <div className="mt-[6px] text-center 2xl:mt-3">
        <Button size="large" variant="secondary" onClick={handleGoToExploreWorlds}>
          {t('landing-page.curated-worlds-cta')}
        </Button>
      </div>
    </section>
  )
}

export default CuratedWorlds
